


































import { ConfigGetters, Features } from "@/config/types";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import ScheduleManagement from "../../components/Schedule/ScheduleManagement.vue";

@Component({
  components: { ScheduleManagement },
})
export default class Schedule extends Vue {
  @Getter(ConfigGetters.EnabledFeatures)
  readonly enabledFeatures!: Features;
}
