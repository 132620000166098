



















































































































































































































































































import { Action, Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import {
  Poll,
  PollStatus,
  PollStartDto,
} from "@/services/api/poll.service.types";
import parser from "cron-parser";
import { ScheduledAds } from "@/store/videoAds/types";
import moment from "moment";
import { PollActions, PollGetters } from "@/components/Polls/types";
import { getPollStatusLabel } from "@/helpers";
import { ConfigGetters, Features } from "@/config/types";

@Component({})
export default class ScheduleManagement extends Vue {
  @Getter(ConfigGetters.EnabledFeatures)
  readonly enabledFeatures!: Features;

  @Action(PollActions.FetchPolls)
  fetchPolls!: () => Promise<void>;
  @Action(PollActions.DeletePoll)
  deletePoll!: (pollId: string) => Promise<void>;
  @Action(PollActions.StartPoll)
  startPoll!: (data: PollStartDto) => Promise<void>;

  @Getter(PollGetters.Polls)
  readonly polls!: Poll[];

  @Action("videoAds/fetchAllScheduledAds")
  fetchAllScheduledAds!: () => void;
  @Action("videoAds/deleteScheduledAd")
  deleteScheduledAdRequest!: (id: string) => void;

  @Getter("videoAds/getAllScheduledAds")
  readonly getAllScheduledAds!: ScheduledAds[];
  @Getter("videoAds/getScheduledAdError")
  readonly getScheduledAdError!: string;

  getPollStatusLabel = getPollStatusLabel;
  PollStatus = PollStatus;

  isLoading = true;
  poll: Poll | null = null;
  dialog = false;
  startDate = new Date();
  datePickerProps = {
    headerColor: "brand",
    color: "brand",
    // format: "24hr"
  };
  textFieldProps = {
    appendOuterIcon: "mdi-calendar",
  };
  expanded = [];
  // tab and dataTable
  tabItems = Array<string>();
  tab = 0;
  iDItemToDelete = "";
  nameItemToDelete = "";
  deleteTitle = "Are you sure you want to delete this Scheduled Ad?";
  scheduledDialog = false;
  searchScheduler = "";
  // tab and dataTable
  events = [
    { text: this.$t("Type"), value: "status" },
    { text: this.$t("Content"), value: "question" },
    { text: this.$t("Created By"), value: "createdByUserInfo.displayName" },
    { text: this.$t("Scheduled For"), value: "startDate" },
    { text: this.$t("Actions"), value: "actions", sortable: false },
    { text: "", value: "data-table-expand" },
  ];
  headersScheduler = [
    {
      text: this.$t("Ad name"),
      value: "schedulable.name",
      width: "10%",
    },
    {
      text: this.$t("Description"),
      value: "name",
      width: "10%",
    },
    {
      text: this.$t("Link"),
      value: "schedulable.clickLink",
      width: "10%",
    },
    {
      text: this.$t("Broadcast"),
      value: "broadcast.broadcastName",
      width: "8%",
    },
    {
      text: this.$t("Cron"),
      value: "cron",
      filterable: false,
      width: "5%",
    },
    {
      text: this.$t("Closable after"),
      value: "schedulable.closableAfterSeconds",
      filterable: false,
      width: "10%",
    },
    {
      text: this.$t("Last Executed"),
      value: "lastExecutedAt",
      filterable: false,
      width: "10%",
    },
    { text: this.$t("Actions"), value: "id", filterable: false, width: "8%" },
  ];

  created() {
    this.fetchAllScheduledAds();
  }

  tabLength(): string {
    const { tab: tabIndex } = this;
    const selectedTab = this.tabItems[tabIndex];

    switch (selectedTab) {
      case "Polls":
        return ` (${this.preparedPolls.length})`;
      case "VideoAds":
        return ` (${this.getAllScheduledAds.length})`;
    }

    return "";
  }

  cronParser(cron: string) {
    try {
      const interval = parser.parseExpression(cron);
      return moment(interval.next().toISOString()).format(
        "YYYY/MM/DD HH:mm:ss"
      );
    } catch (err) {
      return "/";
    }
  }

  closableDuration(duration: string) {
    if (duration == "0") {
      return this.$t("Instantly");
    } else if (duration == "3") {
      return this.$t("3 seconds");
    } else if (duration == "5") {
      return this.$t("5 seconds");
    } else if (duration == "10") {
      return this.$t("10 seconds");
    } else if (duration == "-1") {
      return this.$t("Not closeable");
    } else {
      return duration;
    }
  }

  get preparedPolls() {
    const filteredStatuses = [PollStatus.DRAFT, PollStatus.SCHEDULED];
    return this.polls.filter((p) => filteredStatuses.includes(p.status));
  }

  lastExecutedDate(date: string) {
    if (date) {
      return moment(date.toString()).format("YYYY/MM/DD HH:mm:ss");
    } else {
      return "/";
    }
  }

  async deleteAd(adId: string) {
    this.scheduledDialog = false;
    await this.deleteScheduledAdRequest(adId);
    setTimeout(() => {
      this.fetchAllScheduledAds();
    }, 500);
  }

  showDeletedItem(item: string, name: string) {
    this.iDItemToDelete = item;
    this.nameItemToDelete = name;
    this.scheduledDialog = !this.scheduledDialog;
  }

  deleteItem(poll: Poll) {
    this.deletePoll(poll.id);
  }

  closeDialog() {
    this.dialog = false;
    this.poll = null;
  }

  scheduleDialog(poll: Poll) {
    this.dialog = true;
    this.poll = poll;
  }

  async scheduleEvent() {
    const { poll, startDate } = this;

    if (!poll) return;

    const MINUTE_IN_MS = 1000 * 60;
    const eventDuration = poll.duration * MINUTE_IN_MS;
    const endDate = new Date(startDate.getTime() + eventDuration);

    await this.startPoll({
      id: poll.id,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });

    this.poll = null;
    this.dialog = false;
    this.startDate = new Date();
  }

  getData() {
    this.fetchPolls().then(() => {
      this.isLoading = false;
    });
  }

  mounted() {
    this.getData();

    if (this.enabledFeatures.POLLS) this.tabItems.push("Polls");
    if (this.enabledFeatures.VIDEO_ADS) this.tabItems.push("VideoAds");
    // if (this.enabledFeatures.NOTIFICATIONS) this.tabItems.push("Notification");
  }

  getPollScheduledDate(item: Poll) {
    return item.status === PollStatus.SCHEDULED
      ? this.$d(new Date(item.startDate), "DateTimeShort")
      : "N/A";
  }
}
